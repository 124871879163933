import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';
import 'aos/dist/aos.css';
//sections
import scrollTop from "../../components/scrollTop/index.vue";
import productCard from '@/components/product-card/index.vue';

export default {
  name: "catalog",
  components: {
    productCard,
    scrollTop
  },
  data() {
    return {
      currentCollection: '',
      currentMeta: null,
      slideDownFlag: false,
      slidePrevTop: true,
    }
  },
  head: {
    title: function () {
      return this.currentMeta ? {
        inner: this.currentMeta.title,
        separator: '|',
        complement: 'KATY CORSO',
      } : {
        inner: 'KATY CORSO',
      }
    },
    meta: function () {
      let meta = []
      if (this.currentMeta) {
        let {metaDescription, metaKeywords, metaTitle } = this.currentMeta;

        if (metaDescription) meta.push({n: 'description', c: metaDescription, id: 'description'})
        if (metaKeywords) meta.push({n: 'keywords', c: metaKeywords, id: 'keywords'})
        if (metaTitle) meta.push({n: 'og:title', c: metaTitle, id: 'og:title'})
        if (metaDescription) meta.push({n: 'og:description', c: metaDescription, id: 'og:description'})
      }
      return meta
    }
  },
  watch: {
    '$route.params.slug': {
      immediate: true,
      handler(newVal){
        if (newVal) {
          this.fetchCollections(newVal).then(()=>{
            if(!this.$route.query.collection){
              this.currentCollection = this.collectionsList.collections.data[0].slug
            }
          })
        }
      }
    },
    currentCollection: {
      handler(newVal){
        this.changeCollection(newVal)
      }
    },
    '$route.query.collection': {
      immediate: true,
      handler(newVal){
        if(newVal){
          this.fetchProductList(newVal).then(()=>{
            this._setCurrentCollection()
            if(this.slidePrevTop && this.top){
              setTimeout(()=>{
                window.scrollTo({
                  top: this.top,
                  behavior: "smooth"
                });
                this.changeTopPosition(null)
              },300)
              this.slidePrevTop = false
            }
            if(this.slideDownFlag){
              setTimeout(()=>{
                window.scrollTo({
                  top: 550,
                  behavior: "smooth"
                });
              },300)
              this.slideDownFlag = false
            }
          })
          this.currentCollection = newVal
        }
      }
    }
  },
  created() {

  },
  computed: {
    ...mapGetters({
      collectionsList: 'catalog/collections',
      productList: 'catalog/list',
      productsLoading: 'catalog/productLoading',
      favoriteList: 'basket/favorites',
      isAuth: 'auth/isAuthenticated',
      top: 'catalog/top',
    }),
    formattedList() {
        if (!this.productList) return []
        if (!this.favoriteList) return []
        return this.productList.dresses.data.map(el => {
          const item = this.favoriteList.find(t => t.slug === el.slug)
          el.selected = !!item
          return el
        })
    },
    selectedCollectionDescription(){
      if(!this.collectionsList) return ''
      if(!this.currentCollection) return ''
      let selectedCollection = this.collectionsList.collections.data.find(el => el.slug === this.currentCollection)
      if(selectedCollection !== undefined){
        return selectedCollection.shortDescription
      } else {
        return ''
      }
    }
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  methods: {
    ...mapMutations({
      changeTopPosition: 'catalog/SET_TOP_POSITION'
    }),
    ...mapActions({
      fetchCollections: 'catalog/GET_COLLECTIONS',
      fetchProductList: 'catalog/GET_PRODUCTS'
    }),
    _setCurrentCollection() {
      this.currentMeta = null;
      if (this.productList) {
        let {metaDescription, metaKeywords, metaTitle , title} = this.productList;
        this.currentMeta = {metaDescription, metaKeywords, metaTitle , title};
      }
      this.$emit('updateHead');
    },
    scrollDown(){
      this.slideDownFlag = true
    },
    changeCollection(slug, scroll) {
      if(scroll){
        this.slideDownFlag = true
      }
      this.$router.push({
        name: this.$route.name,
        params: {slug: this.$route.params.slug},
        query: {collection: slug}
      }).catch(()=>{})
    }
  }
}
