export default {
  name: 'scroll-top',
  props: {
  },
  data() {
    return {
      showButton: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.updateScrollState);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScrollState);
  },
  methods: {
    updateScrollState() {
      const scrollPosition = window.scrollY || window.pageYOffset;
      this.showButton = scrollPosition >= window.innerHeight / 2
    },
    scrollToTop(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

  },
}
